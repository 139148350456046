import { Button, Flex, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Mobilenavbar from "../../components/sections/Mobilenavbar";
import Navbar from "../../components/sections/Navbar";
import Footer from "../../components/sections/Footer";
import ARTree from "../../assets/images/Ar&VrPage/tree.png";
import Treee from "../../assets/images/Ar&VrPage/treee.png";
import QR from "../../assets/images/Ar&VrPage/QR.png";
import Underline from "../../assets/images/HomePage/underline.webp";
import BG from "../../assets/images/Ar&VrPage/balls.png";
import Light from "../../assets/images/Ar&VrPage/lights.png";
import Gift from "../../assets/images/Ar&VrPage/gift.png";
import Title from "../../assets/images/Ar&VrPage/title.png";
import Logo from "../../assets/images/Ar&VrPage/logo.png";
import Env from "../../assets/images/Ar&VrPage/christmas_photo_studio_01_4k.hdr";
import { Helmet } from "react-helmet";
import unknownloader from "../../assets/images/Ar&VrPage/circle.svg";
import Christmas from "../../assets/images/Ar&VrPage/christmas ar-10.glb";
import ChristmasIOS from "../../assets/images/Ar&VrPage/christmas ar-10.usdz";
import ShoppingExperience from "../../components/sections/ArVr/ShoppingExperience";
import BlockchainTechnology from "../../components/sections/ArVr/BlockchainTechnology";
import Maintenance from "../../components/sections/ArVr/Maintenance";
import Education from "../../components/sections/ArVr/Education";
import Presentation from "../../components/sections/ArVr/Presentation";
import ARVRheader from "../../assets/images/Ar&VrPage/arvrheader.webp";
import ARnew from "../../assets/images/Ar&VrPage/newar.gif";
import VRnew from "../../assets/images/Ar&VrPage/newvr.gif";
import MRnew from "../../assets/images/Ar&VrPage/newmr.gif";
import VRTraining from "../../components/sections/ArVr/VRTraining";
import VirtualMeeting from "../../components/sections/ArVr/VirtualMeeting";
import Gaming from "../../components/sections/ArVr/Gaming";
import VRLearning from "../../components/sections/ArVr/VRLearning";
import ParticleBackground from "../../components/sections/home/ParticleBackground";
import ParticleTrail from "../../components/sections/home/ParticleTrail";
import BlkRing from "../../assets/images/IndustrysolutionsPage/ring.webp";
import CommonButton from "../../components/CommonButton";
import { HashLink } from "react-router-hash-link";
import confetti from "canvas-confetti";
function ArVr(props) {
  const modelViewerRef = useRef(null);

  const [newanimationName, setnewanimationName] = useState("Initial_anime");
  const [animationStage, setAnimationStage] = useState(1);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  // const [src, setSrc] = useState("");
  // const [scale, setScale] = useState("3 3 3");
  // // Detect device type and set the appropriate source
  // useEffect(() => {
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  //   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //     setSrc(ChristmasIOS); // iOS device
  //     // setScale("14 14 14");
  //   } else {
  //     setSrc(Christmas); // Android device
  //     // setScale("3 3 3"); // Android scale
  //   }
  // }, []);
  // // Event handler to change scale
  // const handleARButtonClick = () => {
  //   setScale("3 3 3"); // Set scale dynamically
  // };

  const handleOpenOverlay = () => {
    setIsOverlayVisible(true);
  };

  const handleCloseOverlay = () => {
    setIsOverlayVisible(false);
  };
  const [arbuttontext, setarbuttontext] = useState(
    <>
      <Flex>
        <Text my={"auto"}>Add Some Festive Gifts!</Text>{" "}
        <Image
          ml={"10px"}
          width="45px"
          objectFit="contain"
          src={Gift}
          alt="Ball Decoration"
        />
      </Flex>
    </>
  );

  const handleModelClick = () => {
    const modelViewer = modelViewerRef.current;
    console.log(modelViewer);
    if (animationStage === 1) {
      modelViewer.currentTime = 15;
      setnewanimationName("Gift_box appear_anime");

      setAnimationStage(2);
      setarbuttontext(
        <>
          <Flex>
            <Text my={"auto"}>Light Up the Magic!</Text>{" "}
            <Image
              ml={"10px"}
              width="50px"
              objectFit="contain"
              src={Light}
              alt="Ball Decoration"
            />
          </Flex>
        </>
      );
    } else if (animationStage === 2) {
      // Play the Gift_box appear animation
      modelViewer.currentTime = 15;
      setnewanimationName("Mala_bulb appear_anime");

      setAnimationStage(3);
      setarbuttontext(
        <>
          <Flex>
            <Text my={"auto"}>Sprinkle Some Ornaments!</Text>{" "}
            <Image
              ml={"10px"}
              width="50px"
              objectFit="contain"
              src={BG}
              alt="Ball Decoration"
            />
          </Flex>
        </>
      );
    } else if (animationStage === 3) {
      // Play the Gift_box appear animation
      modelViewer.currentTime = 15;
      setnewanimationName("Deco appear_anime");

      setAnimationStage(4);
      setarbuttontext(
        <>
          <Flex>
            <Text my={"auto"}>Top It with Our Logo!</Text>{" "}
            <Image
              ml={"10px"}
              width="20px"
              objectFit="contain"
              src={Logo}
              alt="Ball Decoration"
            />
          </Flex>
        </>
      );
    } else if (animationStage === 4) {
      // Play the Gift_box appear animation
      modelViewer.currentTime = 15;
      setnewanimationName("TG_Logo appear_anime");

      setAnimationStage(5);
      setarbuttontext(
        <>
          <Flex>
            <Text my={"auto"}>See the Final Look !!!</Text>{" "}
            <Image
              ml={"10px"}
              width="40px"
              objectFit="contain"
              src={Treee}
              alt="Ball Decoration"
            />
          </Flex>
        </>
      );
    } else if (animationStage === 5) {
      confetti({
        particleCount: 200,
        spread: 100,
        origin: { y: 0.6 },
      });

      // Play the Gift_box appear animation
      modelViewer.currentTime = 15;
      setnewanimationName("Christmas_tree idle_anime");

      setAnimationStage(6);
      setarbuttontext("Deck the Tree Again! ");
    } else if (animationStage === 6) {
      // Play the Gift_box appear animation
      modelViewer.currentTime = 15;
      setnewanimationName("Initial_anime");

      setAnimationStage(1);
      setarbuttontext(
        <>
          <Flex>
            <Text my={"auto"}>Add Some Festive Gifts!</Text>{" "}
            <Image
              ml={"10px"}
              width="45px"
              objectFit="contain"
              src={Gift}
              alt="Ball Decoration"
            />
          </Flex>
        </>
      );
    }
  };

  return (
    <>
      {/* helmet */}
      <Helmet>
        <title>
          Top AR & VR App Development Company | TrackGenesis® | Scotland UK
        </title>
        <meta
          name="title"
          content="Top AR & VR App Development Company | TrackGenesis®️ | Scotland UK "
        />
        <meta
          name="description"
          content="We create immersive experiences in augmented and virtual reality that reflects the client's imagination and requirements.  "
        />
        <link
          href="https://trackgenesis.com/services/ar-vr"
          rel="canonical"
          content="max-age: 1209600, no-cache, no-store, must-revalidate"
        />

        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-197747980-1"
        ></script>
      </Helmet>
      {/* helmet */}
      <Flex
        backgroundColor={"black"}
        flexDirection={"column"}
        overflowX={"hidden"}
        id="arvr"
      >
        {/* new heading Section */}
        <Flex>
          <Image
            alt=" Trackgenesis Extended Reality"
            ml={{ base: "-50px", md: "-40px", lg: "0px" }}
            width={{ base: "270px", md: "400px", lg: "500px" }}
            src={ARVRheader}
          ></Image>
          <Text
            mt={"auto"}
            className="heading"
            mb={{ base: "15px", md: "15px", lg: "30px" }}
            color={"#94DAF8"}
            fontSize={{ base: "30px", md: "45px", lg: "50px" }}
            ml={{ base: "-19%", md: "-19%", lg: "-10%" }}
          >
            Extended Reality
          </Text>
        </Flex>

        <Navbar />
        <Mobilenavbar />

        {/* new content */}

        <ParticleTrail />
        <Flex
          flexDir={"column"}
          position={"relative"}
          className="mainborder"
          mx={{ base: "10px", md: "15px", lg: "30px" }}
          backgroundColor={"rgba(27, 21, 78, 0.38)"}
          backdropFilter="auto"
          backdropBlur="4px"
          pb={"100px"}
        >
          <Image zIndex={3} width={{base:"260px",md:"300px",lg:"500px"}} objectFit={"contain"} position={"absolute"} right={0} src={Title}></Image>
        
          {/* Augmented Section model viewer */}
          <Flex
            pos={"relative"}
           
           mt={{base:"40px",md:"40px",lg:"100px"}}
           
            height={{ base: "65vh", md: "70vh", lg: "75vh" }}
            width={{ base: "90%", md: "90%", lg: "60%" }}
            mx={"auto"}
            backgroundSize={"cover"}
            bgRepeat={"no-repeat"}
            flexDir={"column"}
          >
          {isOverlayVisible && (
        <Flex
          width={"100%"}
          position={"absolute"}
          zIndex={3}
          height={"100%"}
          backgroundColor={"blackAlpha.800"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDir={"column"}
        >
                    
          <Flex flexDir={"column"} p={"20px"} backgroundColor={"wheat"} borderRadius={"5px"} boxShadow={"lg"}>
          <Button width={"20px"} height={"40px"} boxShadow="lg" ml={"auto"} onClick={handleCloseOverlay}>X</Button>
          <Text color={"black"} mb={4}>Scan this QR to view AR on your mobile device</Text>
          <Image borderRadius={"5px"}  boxShadow={"lg"} mx={"auto"} width={"200px"} src={QR}></Image>
</Flex>
        </Flex>
      )}
          
       
            <model-viewer
              id="modelAR"
              ref={modelViewerRef}
              scale={"3 3 3"}
              src={Christmas}
              alt="A 3D model of a Christmas tree"
              ios-src={ChristmasIOS}
              camera-controls
              magic-leap
              ar
              ar-modes="scene-viewer webxr quick-look"
              
              touch-action="pan-y"
              tone-mapping="neutral"
              shadow-intensity="2"
              autoplay
              poster={unknownloader}
              animation-name={newanimationName}
              disable-tap
              // max-camera-orbit="auto 180deg auto"
              max-camera-orbit="auto 90deg auto"
              ar-scale="auto"
              exposure="1"
              environment-image={Env}
              skybox-image={Env}
              skybox-height="7m"
              camera-orbit="150deg 180deg 4m"
              camera-target="0m 2.2m 0.02439m"
              min-camera-orbit="auto auto 9.941m"
              min-field-of-view="30deg"

              // Attach click handler to model-viewer
            >
              <div class="progress-bar hide" slot="progress-bar">
                <div class="update-bar"></div>
              </div>

              <Button
                slot="ar-button"
                id="ar-button"
                zIndex={5}
                borderRadius={"10px"}
                mr={"auto"}
                ml={"20px"}
                mt={"20px"}
                display="block"
                width={"130px"}
                height="37px"
                _hover={{ bg: "#574F48", color: "white" }}
                boxShadow={"lg"}
                fontSize={{ base: "12px", md: "13px", lg: "13px" }}
                backgroundColor={"#6E8E41"}
                border={"none"} // Remove the original border
                outline={"5px solid rgba(184,184,184,0.3)"} // Add an outline instead
                // onClick={handleARButtonClick}
              >
                <Flex>
                  {" "}
                  <Text mt={"10px"}> View in AR</Text>{" "}
                  <Image
                    py={"5px"}
                    my={"auto"}
                    ml={"10px"}
                    width={"30px"}
                    src={ARTree}
                  ></Image>
                </Flex>
              </Button>

              {/* QR Button */}
              <Button
              
                onClick={handleOpenOverlay}
                borderRadius={"10px"}
                mr={"auto"}
                ml={"20px"}
                mt={"20px"}
                display={{base:"none",md:"none",lg:"flex"}}
                width={"fit-content"}
                height="37px"
                _hover={{ bg: "#574F48", color: "white" }}
                boxShadow={"lg"}
                fontSize={{ base: "12px", md: "13px", lg: "13px" }}
                backgroundColor={"#6E8E41"}
                border={"none"} // Remove the original border
                outline={"5px solid rgba(184,184,184,0.5)"} // Add an outline instead
                //  className="animate__anim ated animate__tada animate__infinite	infinite animate__slower arbuttonlanding"
              >
                <Flex>
                  {" "}
                  <Text mt={"10px"}> Place it in your room</Text>{" "}
                  <Image
                    py={"5px"}
                    my={"auto"}
                    ml={"10px"}
                    width={"30px"}
                    src={ARTree}
                  ></Image>
                </Flex>
              </Button>

              {/* choose anime */}

              <Button
                _hover={{
                  backgroundColor: "#51020A", // Background color on hover
                }}
                border={"none"} // Remove the original border
                outline={"5px solid rgba(184,184,184,0.3)"}
                backgroundColor={"#5E783A"}
                color={"white"}
                onClick={handleModelClick}
                display={"flex"}
                position={"absolute"}
                bottom={{ base: 2, md: 2, lg: 5 }}
                left={{ base: 5, md: 5, lg: 10 }}
                width={"fit-content"}
                height="37px"
              >
                <Text> {arbuttontext}</Text>
                {/* <Image
                    py={"5px"}
                    my={"auto"}
                    ml={"10px"}
                    width={"30px"}
                    src={ARTree}
                  ></Image> */}
              </Button>
            </model-viewer>
          </Flex>
          {/* AR intro */}

          <Flex
            id="augmentedreality"
            my={{ base: "30px", md: "30px", lg: "90px" }}
            // p={"60px"}
            mx={"auto"}
            width={{ base: "80%", md: "80%", lg: "900px" }}
            flexDir={{
              base: "column-reverse",
              md: "column-reverse",
              lg: "row",
            }}
          >
            <Flex
              flexDir={"column"}
              mt={{ base: "-60px", md: "-50px", lg: "0px" }}
              justifyContent={"center"}
              alignItems={"center"}
              backgroundColor={"rgba(0, 0, 0, 0.15)"}
              borderRadius={"5px"}
              backdropFilter="auto"
              backdropBlur="2px"
              boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
            >
              <Flex
                flexDir={"column"}
                mr={"auto"}
                ml={{ base: "20px", md: "20px", lg: "45px" }}
                mt={{ base: "100px", md: "100px", lg: "30px" }}
              >
                <Text
                  className="heading"
                  color={"#94DAF8"}
                  fontSize={{ base: "22px", md: "22px", lg: "25px" }}
                >
                  Grow Your Business Using Augmented Reality
                </Text>
                <Image
                  mt={"-10px"}
                  ml={"-10px"}
                  width={"70px"}
                  src={Underline}
                  alt="underline"
                ></Image>
              </Flex>
              <Text
                pr={{ base: "20px", md: "30px", lg: "150px" }}
                pl={{ base: "20px", md: "30px", lg: "50px" }}
                py={{ base: "30px", md: "30px", lg: "30px" }}
                fontSize={{ base: "14px", md: "14px", lg: "17px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                maxW={{ base: "600px", md: "600px", lg: "800px" }}
              >
                Augmented Reality offers the physical world a digital twist.
                From a piece of information to a digital entity, you can add
                spectacular effects and characteristics to the actual world. Our
                augmented reality app drives increased user engagement and
                elevates your brand presence in the digital world.
                TrackGenesis’s AR innovations provide unbelievable experiences
                and build your space among customers for a lifetime.
              </Text>

              <Flex mr={"auto"} ml={"50px"} mt={"-10px"} mb={"20px"}>
                <HashLink smooth to={"/products#arbook"}>
                  <CommonButton btn="View Demo" />
                </HashLink>
              </Flex>
            </Flex>

            <Image
              mr={{ base: "auto", md: "auto", lg: "0px" }}
              alt=" Trackgenesis AR Augmented Reality"
              ml={{ base: "auto", md: "auto", lg: "-150px" }}
              zIndex={2}
              width={{ base: "180px", md: "180px", lg: "280px" }}
              objectFit={"contain"}
              src={ARnew}
            ></Image>
            <Image
              alt=" Trackgenesis Extended Reality"
              width={{ base: "210px", md: "210px", lg: "330px" }}
              objectFit={"contain"}
              zIndex={1}
              mr={{ base: "auto", md: "auto", lg: "0px" }}
              mb={{ base: "-180px", md: "-180px", lg: "0px" }}
              ml={{ base: "auto", md: "auto", lg: "-300px" }}
              src={BlkRing}
            ></Image>
          </Flex>

          {/* AR Content */}

          {/* Shopping Experience */}
          <ShoppingExperience />

          {/* Maintenance */}
          <Maintenance />

          {/* Education */}
          <Education />

          {/* Service*/}
          <BlockchainTechnology />

          {/* Website */}
          <Presentation />

          {/* VR Intro */}
          <Flex id="virtualReality" flexDir={"column"}>
            <Flex
              my={{ base: "30px", md: "30px", lg: "90px" }}
              // p={"60px"}
              mx={"auto"}
              width={{ base: "80%", md: "80%", lg: "900px" }}
              flexDir={{
                base: "column-reverse",
                md: "column-reverse",
                lg: "row",
              }}
            >
              <Flex
                flexDir={"column"}
                mt={{ base: "-60px", md: "-50px", lg: "0px" }}
                justifyContent={"center"}
                alignItems={"center"}
                backgroundColor={"rgba(0, 0, 0, 0.15)"}
                borderRadius={"5px"}
                backdropFilter="auto"
                backdropBlur="2px"
                boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
              >
                <Flex
                  flexDir={"column"}
                  mr={"auto"}
                  ml={{ base: "20px", md: "20px", lg: "45px" }}
                  mt={{ base: "100px", md: "100px", lg: "30px" }}
                >
                  <Text
                    className="heading"
                    color={"#94DAF8"}
                    fontSize={{ base: "22px", md: "22px", lg: "25px" }}
                    pr={"30px"}
                  >
                    Create Immersive Experiences using Virtual Reality
                  </Text>
                  <Image
                    mt={"-10px"}
                    ml={"-10px"}
                    width={"70px"}
                    src={Underline}
                    alt="underline"
                  ></Image>
                </Flex>
                <Text
                  pr={{ base: "20px", md: "30px", lg: "150px" }}
                  pl={{ base: "20px", md: "30px", lg: "50px" }}
                  py={{ base: "30px", md: "30px", lg: "30px" }}
                  fontSize={{ base: "14px", md: "14px", lg: "17px" }}
                  color={"white"}
                  className="content_font"
                  fontWeight={"light"}
                  maxW={{ base: "600px", md: "600px", lg: "800px" }}
                >
                  Step into the virtual world and get connected to people from
                  around the world within the comfort of your home. At
                  TrackGenesis, we work hand-in-hand with the latest
                  technological developments to build the perfect virtual world
                  and ensure the finest VR experience. Our VR developers and 3D
                  model artists are ready to create magical experiences for your
                  customers beyond location constraints.
                </Text>
                <Flex mr={"auto"} ml={"50px"} mt={"-10px"} mb={"20px"}>
                  <HashLink smooth to={"/case-study#fire"}>
                    <CommonButton btn="View Demo" />
                  </HashLink>
                </Flex>
              </Flex>

              <Image
                mr={{ base: "auto", md: "auto", lg: "0px" }}
                alt="VR Virtual Reality TrackGenesis"
                ml={{ base: "auto", md: "auto", lg: "-150px" }}
                zIndex={2}
                width={{ base: "180px", md: "180px", lg: "280px" }}
                objectFit={"contain"}
                src={VRnew}
              ></Image>
              <Image
                alt="VR Virtual Reality TrackGenesis"
                width={{ base: "210px", md: "210px", lg: "330px" }}
                objectFit={"contain"}
                zIndex={1}
                mr={{ base: "auto", md: "auto", lg: "0px" }}
                mb={{ base: "-180px", md: "-180px", lg: "0px" }}
                ml={{ base: "auto", md: "auto", lg: "-300px" }}
                src={BlkRing}
              ></Image>
            </Flex>

            {/* Training */}
            <VRTraining />

            {/* Meeting */}
            <VirtualMeeting />

            {/* Gaming */}
            <Gaming />

            {/* Learning */}
            <VRLearning />
          </Flex>
          {/* Mixed Reality Intro */}

          <Flex
            my={{ base: "30px", md: "30px", lg: "90px" }}
            // p={"60px"}
            mx={"auto"}
            width={{ base: "80%", md: "80%", lg: "900px" }}
            flexDir={{
              base: "column-reverse",
              md: "column-reverse",
              lg: "row",
            }}
          >
            <Flex
              id="mixedreality"
              flexDir={"column"}
              mt={{ base: "-60px", md: "-50px", lg: "0px" }}
              justifyContent={"center"}
              alignItems={"center"}
              backgroundColor={"rgba(0, 0, 0, 0.15)"}
              borderRadius={"5px"}
              backdropFilter="auto"
              backdropBlur="2px"
              boxShadow={"0px 0px 32.5px -12px rgba(1, 177, 216, 0.45) inset"}
            >
              <Flex
                flexDir={"column"}
                mr={"auto"}
                ml={{ base: "20px", md: "20px", lg: "45px" }}
                mt={{ base: "100px", md: "100px", lg: "30px" }}
              >
                <Text
                  className="heading"
                  color={"#94DAF8"}
                  fontSize={{ base: "22px", md: "22px", lg: "25px" }}
                  pr={"30px"}
                >
                  Mixed Reality
                </Text>
                <Image
                  mt={"-10px"}
                  ml={"-10px"}
                  width={"70px"}
                  src={Underline}
                  alt="underline"
                ></Image>
              </Flex>
              <Text
                pr={{ base: "20px", md: "30px", lg: "150px" }}
                pl={{ base: "20px", md: "30px", lg: "50px" }}
                py={{ base: "30px", md: "30px", lg: "30px" }}
                fontSize={{ base: "14px", md: "14px", lg: "17px" }}
                color={"white"}
                className="content_font"
                fontWeight={"light"}
                maxW={{ base: "600px", md: "600px", lg: "800px" }}
              >
                Imagine if you could interact with virtual elements in the real
                world!
                <br />
                <br />
                Blending the physical and digital realms opens up new
                possibilities for MR in business. Mixed reality makes it
                possible to hold interactive virtual conferences, hands-on
                training in a simulated environment that transcends geographical
                barriers, and dynamic data visualization.
              </Text>
            </Flex>

            <Image
              mr={{ base: "auto", md: "auto", lg: "0px" }}
              alt=" Mixed Reality TrackGenesis"
              ml={{ base: "auto", md: "auto", lg: "-150px" }}
              zIndex={2}
              width={{ base: "180px", md: "180px", lg: "280px" }}
              objectFit={"contain"}
              src={MRnew}
            ></Image>
            <Image
              alt=" Mixed Reality TrackGenesis"
              width={{ base: "210px", md: "210px", lg: "330px" }}
              objectFit={"contain"}
              zIndex={1}
              mr={{ base: "auto", md: "auto", lg: "0px" }}
              mb={{ base: "-180px", md: "-180px", lg: "0px" }}
              ml={{ base: "auto", md: "auto", lg: "-300px" }}
              src={BlkRing}
            ></Image>
          </Flex>
        </Flex>
        <ParticleBackground />
        <Footer />
      </Flex>
    </>
  );
}

export default ArVr;
