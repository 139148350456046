import React, { useState } from "react";
import { Flex, Text, Image, Box, Divider } from "@chakra-ui/react";
import { VscMute, VscUnmute } from "react-icons/vsc";

import trackgenesisVideo from "../../../assets/images/AboutUsPage/trackgenesis-video.mp4";
import Underline from "../../../assets/images/HomePage/underline.webp";
import AboutRobo from "../../../assets/images/HomePage/about.png";
import CommonButton from "../../CommonButton";

function AboutusSection(props) {
  const [openIndex, setOpenIndex] = useState(null);
  const [mute, setMute] = useState(true);
  const handleBoxClick = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };
  return (
    <Flex
      py={{ base: "100px", md: "100px", lg: "0px" }}
      height={{ base: "fit-content", md: "fit-content", lg: "100vh" }}
      width={"100%"}
    >
      <Flex
        width={"100%"}
        my={"auto"}
        flexDir={{ base: "column-reverse", md: "column-reverse", lg: "row" }}
      >
        {/* Video Flex */}
        <Flex
          position={"relative"}
          mt={{ base: "40px", md: "40px", lg: "0px" }}
          p={3}
          width={{ base: "100%", md: "100%", lg: "100%" }}
        >
          <Image
            pos={"absolute"}
            bottom={0}
            left={{ base: 1, md: 1, lg: 20 }}
            zIndex={2}
            src={AboutRobo}
            width={{ base: "100px", md: "150px", lg: "250px" }}
            objectFit={"contain"}
          ></Image>
          <Flex
            position={"relative"}
            mx={"auto"}
            my={"auto"}
            // p={3}
            border={"1px solid #01B1D8"}
            borderRadius={"10px"}
            width={{ base: "100%", md: "100%", lg: "70%" }}
            boxShadow="0px 0px 22.7px 0px #01B1D8"
          >
            <Flex mx={"auto"}>
              <video
                playsInline
                className="videobox"
                src={trackgenesisVideo}
                style={{ borderRadius: "10px" }}
                autoPlay={true}
                muted={mute}
                controls={false}
                loop
              />
            </Flex>

            <Flex
              borderRadius={"10px"}
              pos={"absolute"}
              width={"100%"}
              height={"100%"}
              backgroundColor={"blackAlpha.800"}
            >
              <Flex
                backgroundColor={"rgba(0, 0, 0, 0.15)"}
                borderRadius={"5px"}
                backdropFilter="auto"
                backdropBlur="10px"
                py={"20px"}
                px={"50px"}
                boxShadow={
                  "0px 0px 32.5px -12px rgba(255, 255, 255, 0.45) inset"
                }
                my={"auto"}
                w={"100%"}
                flexDir={"column"}
              >
                <Text
                  mx={"auto"}
                  css={{
                    background:
                      "linear-gradient(to right, rgba(148, 218, 248, 1) 0%, rgba(255, 255, 255, 0.4) 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  color={"white"}
                  textAlign={"center"}
                  width={{ base: "90%", md: "70%", lg: "70%" }}
                  px={{ base: "10px", md: "10px", lg: "15px" }}
                  className="train "
                  fontSize={{ base: "20px", md: "38px", lg: "60px" }}
                >
                  {/* We Take You to the World of Web3 */}
                  <div class="sp-container">
                    <div class="sp-content">
                      <h2 class="frame-5">
                        <span>We Take You to the World of</span>

                        <span>Web3</span>
                      </h2>
                    </div>
                  </div>
                </Text>
           
              </Flex>

              {/* <Box
              position={"absolute"}
              p={2}
              height={"fit-content"}
              width={"fit-content"}
              backgroundColor={"rgba(0,0,0,0.5)"}
              color={"white"}
              boxShadow={"lg"}
              borderRadius={"50%"}
            >
              {mute ? (
                <VscMute
                  cursor={"pointer"}
                  size={"20"}
                  onClick={() => {
                    setMute(!mute);
                  }}
                />
              ) : (
                <VscUnmute
                  cursor={"pointer"}
                  size="20"
                  onClick={() => {
                    setMute(!mute);
                  }}
                />
              )}
            </Box> */}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default AboutusSection;
